import type { Table } from '../Cells/types';

export const salaryHeader = ({ table }: { table: Table }) => {
  const period = table.options.meta?.params.targetPeriod;
  const targetCurrency = table.options.meta?.params.targetCurrency;
  const headerLabel = {
    HOUR: 'Hour rate',
    MONTH: 'Month rate',
    YEAR: 'Annual rate',
    default: 'Salary',
  }[period ?? (targetCurrency ? 'MONTH' : 'default')];
  return headerLabel;
};
