import { yupResolver } from '@hookform/resolvers/yup';
import type {
  DialogContentProps,
  DialogProps,
  DialogTriggerProps,
} from '@radix-ui/react-dialog';
import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { type InferType, object, string } from 'yup';

import { useExternalCandidate } from '@/hooks/useExternalCandidate';
import useForm from '@/hooks/useForm';
import { usePatchExternalCandidate } from '@/hooks/usePatchExternalCandidate';

import Button from '../Button';
import MultiLineInput from '../Form/Fields/MultiLineInput';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from '../ui/dialog';

type Props = {
  dialogProps?: DialogProps;
  dialogTriggerProps?: DialogTriggerProps;
  dialogContentProps?: DialogContentProps;
  id: number;
  initialData?: string;
  noteId?: number;
};
const AddNoteDialog: FC<PropsWithChildren<Props>> = ({
  dialogContentProps,
  dialogProps,
  dialogTriggerProps,
  children,
  noteId,
  id,
  initialData = '',
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<InferType<typeof schema>>({
    resolver: yupResolver(schema),
  });
  const { mutate, isLoading } = usePatchExternalCandidate(() => {
    dialogProps?.onOpenChange?.(false);
    setDialogOpen(false);
    reset();
  });
  const { data } = useExternalCandidate(id);
  useEffect(() => {
    setValue('text', initialData);
    return () => setValue('text', '');
  }, [initialData, noteId, dialogProps?.open]);
  if (!data) return null;

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen} {...dialogProps}>
      <DialogTrigger {...dialogTriggerProps}>{children}</DialogTrigger>
      <DialogContent {...dialogContentProps}>
        <DialogHeader>New note</DialogHeader>
        <form
          onSubmit={handleSubmit((formData) =>
            mutate({
              id,
              data: {
                notes: noteId
                  ? [
                      ...data.notes.filter(
                        ({ id: currentId }) => currentId !== noteId
                      ),
                      { text: formData.text },
                    ]
                  : [...data.notes, { text: formData.text }],
              },
            })
          )}
          className="flex flex-col gap-3"
        >
          <MultiLineInput
            variant="transparent"
            {...register('text')}
            error={errors.text?.message}
          />
          <Button isLoading={isLoading} type="submit">
            Save
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddNoteDialog;

const schema = object({
  text: string().required('This field is required'),
});
