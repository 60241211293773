import type { FC } from 'react';

import Text from '@/components/Text';
import type { ExternalCandidateList } from '@/services/externalCandidates';

import type { Row, Table } from './types';

type Props = {
  table: Table;
  row: Row;
  salary: ExternalCandidateList['salary'];
};
const Salary: FC<Props> = ({ table, row, salary }) => {
  const nonConvertedSalary = row.original.salary;
  const currentCurrency = row.original.currency;
  const currentPeriod = row.original.paymentPeriod;
  const targetCurrency = table.options.meta?.params.targetCurrency;
  const targetPeriod = table.options.meta?.params.targetPeriod;
  const currencySymbol = {
    PLN: 'zł',
    EUR: '€',
    USD: '$',
    default: '',
  }[targetCurrency ?? (targetPeriod ? 'PLN' : currentCurrency ?? 'default')];

  const rowPeriod = {
    HOUR: '/hr',
    MONTH: '/mo',
    YEAR: '/yr',
    default: '',
  }[currentPeriod ?? 'default'];
  const textWithCurrencySymbol = (selectedSalary: number | null) => {
    if (currencySymbol === 'zł') return `${selectedSalary} ${currencySymbol}`;
    return `${currencySymbol} ${selectedSalary}`;
  };
  if (!salary && !nonConvertedSalary) return null;
  if (!targetCurrency && !targetPeriod)
    return (
      <Text className="whitespace-nowrap">{`${textWithCurrencySymbol(
        nonConvertedSalary
      )} ${rowPeriod}`}</Text>
    );
  return (
    <Text className="whitespace-nowrap">{textWithCurrencySymbol(salary)}</Text>
  );
};

export default Salary;
