import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@mindpal-co/mindpal-ui';
import classNames from 'classnames';
import Link from 'next/link';
import type { FC } from 'react';

import Text from '@/components/Text';
import type { ExternalCandidateList } from '@/services/externalCandidates';

import AddCandidateToCollection from '../../AddCandidateToCollectionDialog';
import ButtonWithLabelOnHover from '../../ButtonWithLabelOnHover';
import type { Row } from './types';

type Props = {
  value: ExternalCandidateList['collections'];
  row: Row;
};
const Collections: FC<Props> = ({ value, row }) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <Text className="flex gap-1 whitespace-nowrap">
        {value.length > 0 && (
          <Link
            replace
            href={{
              pathname: '/dashboard',
              query: { collection: value?.[0].id },
            }}
          >
            <Text
              title={value?.[0].title}
              className="w-full max-w-[150px] truncate text-neutral-100"
            >
              {value?.[0].title}
            </Text>
          </Link>
        )}
        {value.length > 1 && (
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger>
                <Text
                  as="span"
                  variant="body-small"
                  className="text-neutral-300"
                >
                  {`(+${value.length - 1})`}
                </Text>
              </TooltipTrigger>
              <TooltipContent className="flex flex-col gap-1 rounded-lg">
                {value.map(({ id, title }) => (
                  <Link
                    replace
                    href={{
                      pathname: 'dashboard',
                      query: { collection: id },
                    }}
                    key={id}
                    className="text-neutral-1000"
                  >
                    {title}
                  </Link>
                ))}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </Text>
      <AddCandidateToCollection
        dialogTriggerProps={{ asChild: true }}
        candidatesId={[Number(row.id)]}
      >
        <ButtonWithLabelOnHover
          icon={faPlus}
          iconClassName={classNames({
            'bg-neutral-800 p-1 aspect-square rounded-full group-hover:!bg-transparent group-hover:!p-0 group-transition-all duration-200':
              value.length !== 0,
          })}
        >
          Add to collection
        </ButtonWithLabelOnHover>
      </AddCandidateToCollection>
    </div>
  );
};

export default Collections;
