import type { FC } from 'react';

import { useDashboard } from '@/stores/useDashboardStore';

import Text from '../Text';
import { Switch } from '../ui/switch';

type Props = {
  candidatesCount: number;
  filteredCandidates?: number;
  semanticCandidates?: number;
};
const CandidatesCount: FC<Props> = ({
  candidatesCount,
  filteredCandidates,
  semanticCandidates,
}) => {
  const { showSuggestedCandidates, setShowSuggestedCandidates } =
    useDashboard();

  if (
    typeof filteredCandidates === 'number' &&
    typeof semanticCandidates === 'number'
  )
    return (
      <div className="flex items-center gap-1">
        <div className="flex divide-x divide-neutral-700 [&_>*]:px-2">
          <Text variant="body-caption">
            Candidates: <span className="font-semibold">{candidatesCount}</span>
          </Text>
          <Text variant="body-caption">
            Best Candidates:{' '}
            <span className="font-semibold text-primary-300">
              {filteredCandidates}
            </span>
          </Text>
          <Text variant="body-caption">
            {showSuggestedCandidates ? 'Suggested Matches' : 'Show Suggested'}:{' '}
            <span className="font-semibold">{semanticCandidates}</span>
          </Text>
        </div>
        <Switch
          checked={showSuggestedCandidates}
          onCheckedChange={setShowSuggestedCandidates}
          size="sm"
        />
        <Text variant="body-caption">
          {showSuggestedCandidates ? 'On' : 'Off'}
        </Text>
      </div>
    );
  return (
    <Text variant="body-caption" className="whitespace-nowrap">
      Candidates: {candidatesCount}
    </Text>
  );
};

export default CandidatesCount;
