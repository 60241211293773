import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { CellContext } from '@tanstack/react-table';
import Link from 'next/link';
import type { FC } from 'react';

import CircularLoadingIndicator from '@/components/CircularLoadingIndicator';
import Text from '@/components/Text';
import type { ExternalCandidateList } from '@/services/externalCandidates';

import EditableCell from '../EditableCell';

type Props = {
  cellProps: CellContext<ExternalCandidateList, ExternalCandidateList['name']>;
  onRetry: (id: number) => void;
};
const FullName: FC<Props> = ({ cellProps, onRetry }) => {
  const { getValue, row } = cellProps;

  if (row.original.status === 'PROCESSING')
    return (
      <Text as="span" className="flex items-center gap-1">
        {getValue()}
        <CircularLoadingIndicator size="sm" />
      </Text>
    );
  if (row.original.status === 'FAILED')
    return (
      <button
        type="button"
        onClick={() => onRetry(row.original.id)}
        className="flex items-center gap-1 text-error"
      >
        Retry
        <FontAwesomeIcon icon={faRotateRight} />
      </button>
    );
  if (cellProps.table.options.meta?.editModeActive)
    return <EditableCell cellProps={cellProps} />;
  return (
    <Link
      title={getValue()}
      onMouseEnter={() =>
        cellProps.table.options.meta?.onProfilePrefetch(Number(row.id))
      }
      href={`/dashboard/${row.id}`}
    >
      <Text
        variant="body-small"
        data-joyride="profile-link"
        className="max-w-[17rem] truncate whitespace-nowrap font-semibold underline"
      >
        {getValue() || 'Not specified'}
      </Text>
    </Link>
  );
};

export default FullName;
