import { useState } from 'react';

export const useControlledDialog = (
  dialog: (props: {
    dialogProps: {
      open: boolean;
      onOpenChange: (open: boolean) => void;
    };
  }) => JSX.Element | null
) => {
  const [open, setOpen] = useState(false);
  const toggleVisibility = () => setOpen((prev) => !prev);
  return {
    toggleVisibility,
    dialog: dialog({ dialogProps: { open, onOpenChange: setOpen } }),
  };
};
