import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@iconify/react';
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@mindpal-co/mindpal-ui';
import { type FC, useState } from 'react';
import { Controller } from 'react-hook-form';

import useForm from '@/hooks/useForm';
import {
  defaultFilters,
  useDashboardFiltersStore,
} from '@/stores/useDashboardFiltersStore';
import { useDashboard } from '@/stores/useDashboardStore';

import Button from '../Button';
import ConfirmationDialog from '../ConfirmationDialog';
import TextInputField from '../Form/Fields/TextInputField';
import { Select } from '../Select';
import Text from '../Text';
import { TooltipContent } from '../ui/tooltip';

type Props = {
  onSearchChange: (search: string) => void;
  setSearchType: (searchType: 'advanced' | 'simple') => void;
  showDeleteSearch?: boolean;
};

type FormData = {
  search: string;
  searchType: 'advanced' | 'simple';
};

const DashboardControls: FC<Props> = ({
  onSearchChange,
  setSearchType,
  showDeleteSearch = false,
}) => {
  const { searchType, search } = useDashboard();

  const { filters } = useDashboardFiltersStore();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [dataToConfirm, setDataToConfirm] = useState<FormData>({
    search: '',
    searchType: 'simple',
  });

  const options = [
    {
      value: 'advanced',
      label: (
        <div className="flex gap-1">
          <p>Advanced</p>
          <DashboardControlsTooltip />
        </div>
      ),
    },
    {
      value: 'simple',
      label: (
        <div className="flex gap-1">
          <p>Basic Search</p>
          <DashboardControlsTooltip />
        </div>
      ),
    },
  ];
  const { handleSubmit, control, register, reset, watch } = useForm<FormData>({
    defaultValues: {
      search,
      searchType,
    },
  });

  const onSubmit = (data: FormData) => {
    onSearchChange(data.search);
    setSearchType(data.searchType);
  };

  const showConfirm =
    watch('searchType') === 'advanced' && watch('search').length < 20;

  const showOverrideInformation =
    JSON.stringify(filters) !== JSON.stringify(defaultFilters) &&
    searchType === 'simple' &&
    watch('searchType') === 'advanced';

  const confirmMessage = (() => {
    if (showOverrideInformation && showConfirm)
      return 'Are you sure you want to continue searching using the advanced search? We suggest using the basic search for a quick search using simple phrases. Your current filters will be overriden by the advanced search.';
    if (showOverrideInformation)
      return 'Are you sure you want to continue? Your current filters will be overriden by the advanced search.';
    if (showConfirm)
      return 'Are you sure you want to continue searching using the advanced search? We suggest using the basic search for a quick search using simple phrases';
  })();
  return (
    <>
      <form
        className="flex flex-1 flex-wrap items-center"
        onSubmit={handleSubmit((data) => {
          if (showConfirm || showOverrideInformation) {
            setDataToConfirm(data);
            setShowConfirmDialog(true);
            return;
          }
          onSubmit(data);
        })}
      >
        <Controller
          control={control}
          name="searchType"
          render={({ field: { onChange, value } }) => (
            <Select
              isSearchable={false}
              variant="gray"
              options={options}
              onChange={(newValue) => onChange(newValue?.value)}
              value={options.find(
                ({ value: currentValue }) => value === currentValue
              )}
              classNames={{
                valueContainer: () => '!py-0 !px-4',
                singleValue: () => '!text-neutral-100',
                control: () => 'rounded-r-none',
                menuList: () => '[&_#tooltipTrigger]:hidden',
              }}
              className="h-full"
            />
          )}
        />
        <div className="relative flex flex-1 items-center">
          <TextInputField
            {...register('search')}
            prefixIcon={faSearch}
            placeholder="I am looking for..."
            canHaveErrorMessage={false}
            className="flex-1 rounded-none bg-neutral-900"
            wrapClassName="h-full"
            variant="grayOutline"
            containerId="joyride-search"
          />
          {showDeleteSearch && (
            <button
              type="button"
              className="absolute right-2 flex h-2/3 items-center gap-1 rounded-md bg-[#893948] px-1 text-body-small"
              onClick={() => {
                reset({ search: '', searchType: 'simple' });
                onSearchChange('');
                setSearchType('simple');
              }}
            >
              <Icon icon="material-symbols-light:close" className="text-xl" />
              Delete Search
            </button>
          )}
        </div>
        <Button
          variant="jbPrimary"
          className="h-full rounded-l-none bg-primary-700 !px-7 before:w-[150%]"
          type="submit"
        >
          <FontAwesomeIcon icon={faSearch} />
        </Button>
      </form>
      <ConfirmationDialog
        confirmMessage={confirmMessage ?? ''}
        onConfirm={() => {
          onSubmit(dataToConfirm);
        }}
        open={showConfirmDialog}
        onOpenChange={setShowConfirmDialog}
      />
    </>
  );
};

export default DashboardControls;

const DashboardControlsTooltip = () => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipContent
          showArrow={false}
          className="ml-[6.5rem] mt-3 flex w-[30rem] gap-2 rounded-lg border border-neutral-700 bg-neutral-1000 !p-2"
          side="bottom"
        >
          <div className="flex-1 rounded-lg bg-neutral-900 px-3 py-2">
            <Text variant="jb-body-medium">Advanced Search</Text>
            <Text
              variant="jb-body-small"
              className="mb-2 mt-1 border-b border-neutral-700 pb-2"
            >
              <span className="text-neutral-300">Features Available:</span> Job
              Matching, Automated Filters
            </Text>
            <ul className="list-inside list-disc text-body-small">
              <li>City, Country</li>
              <li>Job Start</li>
              <li>Seniority Level</li>
              <li>Willingness to Relocate</li>
              <li>Skills, Language</li>
              <li>Commercial Experience</li>
            </ul>
          </div>
          <div className="flex-1 rounded-lg bg-neutral-900 px-3 py-2">
            <Text variant="jb-body-medium">Basic Search</Text>
            <Text
              variant="jb-body-small"
              className="mb-2 mt-1 border-b border-neutral-700 pb-2 text-neutral-300"
            >
              Quick and efficient, for fast outsourcing and simple phrases
            </Text>
            <ul className="list-inside list-disc text-body-small">
              <li>Full Name</li>
              <li>Email Address</li>
              <li>Talent Pool Name</li>
            </ul>
          </div>
        </TooltipContent>
        <TooltipTrigger id="tooltipTrigger" className="z-10">
          <Icon icon="material-symbols-light:info-outline" fontSize={24} />
        </TooltipTrigger>
      </Tooltip>
    </TooltipProvider>
  );
};
