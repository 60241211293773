import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';

import Text from '@/components/Text';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useControlledDialog } from '@/hooks/useControlledDialog';
import { useExternalCandidate } from '@/hooks/useExternalCandidate';
import { usePatchExternalCandidate } from '@/hooks/usePatchExternalCandidate';
import type { Note as NoteType } from '@/services/externalCandidates';
import { dateFormatter } from '@/utils/date';

import AddNoteDialog from '../AddNoteDialog';

type Props = {
  note: NoteType;
  candidateId: number;
};
const Note: FC<Props> = ({ note, candidateId }) => {
  const { mutate } = usePatchExternalCandidate();
  const { data } = useExternalCandidate(candidateId);
  const { dialog: editNoteDialog, toggleVisibility: toggleEditNoteDialog } =
    useControlledDialog((props) => (
      <AddNoteDialog
        id={candidateId}
        initialData={note.text}
        noteId={note.id}
        {...props}
      />
    ));
  if (!data) return null;
  return (
    <div className="flex h-52 shrink-0 flex-col overflow-auto rounded-lg ">
      <div className="flex items-center justify-between  bg-neutral-200 px-5 py-2">
        <Text variant="body-small" className="font-semibold text-neutral-1000">
          {dateFormatter({
            date: note.createdAt,
            format: { dateStyle: 'short' },
          })}
        </Text>
        <DropdownMenu>
          <DropdownMenuTrigger className="-mr-2 px-2">
            <FontAwesomeIcon
              className="text-neutral-1000"
              icon={faEllipsisVertical}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="pointer-events-autow-fit ">
            <DropdownMenuItem onSelect={toggleEditNoteDialog}>
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() =>
                mutate({
                  id: candidateId,
                  data: {
                    notes: data.notes.filter(
                      ({ id: noteId }) => noteId !== note.id
                    ),
                  },
                })
              }
            >
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="scroll-transparent flex-1 overflow-auto break-words  bg-neutral-0 p-5 text-neutral-1000">
        {note.text}
      </div>
      {editNoteDialog}
    </div>
  );
};

export default Note;
