import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import { type FC } from 'react';

import Button from '@/components/Button';
import GenerateCVDialog from '@/components/CVGenerator/GenerateCVDialog';
import Text from '@/components/Text';

import ButtonWithLabelOnHover from '../../ButtonWithLabelOnHover';
import type { Row, Table } from './types';

type Props = {
  row: Row;
  table: Table;
};
const Resume: FC<Props> = ({ row, table }) => {
  const { getIsSomeRowsSelected } = table;
  if (row.original.cvContexts.length > 0)
    return (
      <Link
        href={{
          pathname: `/dashboard/generator/${row.original.cvContexts?.[0].id}`,
          query: {
            tabs: row.original.cvContexts.slice(1).map(({ id }) => id),
          },
        }}
      >
        <Button
          variant="whiteOutline"
          size="sm"
          tabIndex={-1}
          prefixIcon="mdi:resume"
          data-joyride="resume"
          iconClassName="text-sm"
          className="w-max !py-0.5 hover:bg-neutral-800"
        >
          <Text variant="body-caption">Open</Text>
        </Button>
      </Link>
    );
  return (
    <GenerateCVDialog
      profileId={Number(row.id)}
      dialogTriggerProps={{
        asChild: true,
        disabled: getIsSomeRowsSelected() || row.original.status !== 'READY',
      }}
    >
      <ButtonWithLabelOnHover icon={faPlus}>Generate</ButtonWithLabelOnHover>
    </GenerateCVDialog>
  );
};

export default Resume;
