/* eslint-disable unused-imports/no-unused-vars */

import { Icon } from '@iconify/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  ColumnOrderState,
  RowData,
  SortingState,
} from '@tanstack/react-table';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { motion } from 'framer-motion';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { LocalStorage } from '@/config/localStorage';
import { useDashboardOnboarding } from '@/hooks/Onboarding/useDashboardOnboarding';
import { useOnboarding } from '@/hooks/Onboarding/useOnboarding';
import { useGetReasoningSearch } from '@/hooks/useGetReasoningSearch';
import { usePatchExternalCandidate } from '@/hooks/usePatchExternalCandidate';
import { queries } from '@/queries';
import { DjangoDataZodSchema } from '@/services/common';
import {
  externalCandidateListSchema,
  postRetryExternalCandidate,
} from '@/services/externalCandidates';
import { useDashboardFiltersStore } from '@/stores/useDashboardFiltersStore';
import { useDashboard } from '@/stores/useDashboardStore';
import {
  getSavedColumnOrder,
  getSavedVisibility,
  saveColumnOrder,
  saveVisibility,
} from '@/utils/table';

import type { DashboardParams } from '../../../stores/useDashboardStore';
import Joyride from '../../Joyride';
import Table from '../../Table';
import SalaryColumnConfigurationDialog from '../SalaryColumnConfigurationDialog';
import SelectModeControls from '../SelectModeControls';
import { columns } from './columns';

declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    updateData: (rowId: string, columnId: string, value: unknown) => void;
    editModeActive: boolean;
    setEditModeActive: Dispatch<SetStateAction<boolean>>;
    onProfilePrefetch: (id: number) => void;
    params: DashboardParams;
  }
}

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    altHeader?: string;
  }
}

type Props = {
  search: string;
  collections: number[];
  filtersId?: number;
};
const DashboardTableLayout: FC<Props> = ({
  search,
  collections,
  filtersId,
}) => {
  const queryClient = useQueryClient();

  const { mutate } = useOnboarding();
  const {
    onboardingState: [{ run, steps }, setState],
    fixtures,
  } = useDashboardOnboarding();
  const { selectedContexts, setSelectedContexts, showSuggestedCandidates } =
    useDashboard();

  const { filters } = useDashboardFiltersStore();

  const { data: reasoningSearchResult } = useGetReasoningSearch(filtersId);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [editModeActive, setEditModeActive] = useState(false);
  const { mutate: patchExternalCandidate } = usePatchExternalCandidate();

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const rowSelection = Object.fromEntries(
    selectedContexts.map(({ id }) => [id, true])
  );
  const params = useDashboard();

  const { mutate: retry } = useMutation({
    mutationFn: (id: number) => postRetryExternalCandidate(id),
    mutationKey: ['externalCandidateRetry'],
    onSuccess: () => {
      queryClient.invalidateQueries(queries.externalCandidates.list._def);
    },
  });

  const { data: apiData, isPreviousData } = useQuery({
    ...queries.externalCandidates.list({
      pagination: params.pagination,
      search,
      collections,
      salary_order: params.sortingState,
      target_currency: params.targetCurrency,
      target_period: params.targetPeriod,
      filtersId,
      showSuggestedCandidates,
      filters,
    }),
    refetchInterval: (queryData) =>
      queryData?.results.some(({ status }) => status === 'PROCESSING')
        ? 1000 * 5
        : false,
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
  });

  const data =
    run && (apiData?.count ?? 0) <= 0
      ? DjangoDataZodSchema(externalCandidateListSchema).parse(fixtures)
      : apiData;

  const table = useReactTable({
    defaultColumn: {
      enableSorting: false,
    },
    enableColumnResizing: false,
    meta: {
      onProfilePrefetch: (id) =>
        queryClient.prefetchQuery(queries.externalCandidates.detail(id)),

      editModeActive,
      setEditModeActive,
      updateData: (rowId, columnId, value) => {
        patchExternalCandidate({
          id: Number(rowId),
          data: { [columnId]: value },
        });
      },
      params,
    },
    enableSortingRemoval: true,
    manualSorting: true,
    getRowId: ({ id }) => id.toString(),
    columns: useMemo(() => columns({ onRetry: retry }), [retry]),
    data: useMemo(
      () =>
        data?.results
          ? data.results.map((result) => ({
              ...result,
              reasoningSearchResult,
              filters,
            }))
          : [],
      [JSON.stringify(data), reasoningSearchResult, filters]
    ),
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: { left: ['select', 'settings'] },
      columnVisibility,
      columnOrder,
      rowSelection,
      sorting: params.sortingState,
    },
    onColumnOrderChange: (updaterOrValue) => {
      setColumnOrder(updaterOrValue);
      saveColumnOrder(
        columnOrder,
        updaterOrValue,
        LocalStorage.DASHBOARD_LIST_ORDER
      );
    },
    onColumnVisibilityChange: (updaterOrValue) => {
      setColumnVisibility(updaterOrValue);
      saveVisibility(
        columnVisibility,
        updaterOrValue,
        LocalStorage.DASHBOARD_LIST_VISIBILITY
      );
    },
    onSortingChange: (updater) => {
      const setNewSortingParam = (state: SortingState) =>
        params.setParams({
          salaryOrder: state.map(
            ({ desc, id }) => `${desc ? '-' : ''}${id}`
          )[0],
        });

      if (typeof updater === 'function') {
        const newSort = updater(params.sortingState);
        return setNewSortingParam(newSort);
      }
      setNewSortingParam(updater);
    },

    enableRowSelection: true,
    onRowSelectionChange: (updater) => {
      const getContextObjectFromSelectedRows = (ids: {
        [key: string]: boolean;
      }) => {
        const newIds = Object.keys(ids)
          .filter((oldId) => !Object.keys(rowSelection).includes(oldId))
          .map((id) => Number(id));

        const newSelectedContexts =
          data?.results.filter(({ id: candidateId }) =>
            newIds.includes(candidateId)
          ) ?? [];
        return [
          ...newSelectedContexts,
          ...selectedContexts.filter(({ id }) =>
            Object.keys(ids).includes(id.toString())
          ),
        ];
      };

      if (typeof updater === 'function') {
        return setSelectedContexts(
          getContextObjectFromSelectedRows(updater(rowSelection))
        );
      }
      setSelectedContexts(getContextObjectFromSelectedRows(updater));
    },
  });

  useEffect(() => {
    setColumnVisibility(
      getSavedVisibility(LocalStorage.DASHBOARD_LIST_VISIBILITY)
    );
    setColumnOrder(getSavedColumnOrder(LocalStorage.DASHBOARD_LIST_ORDER));
  }, []);

  return (
    <>
      <Joyride
        disableScrollParentFix
        onFinished={() => {
          setState((prev) => ({ ...prev, run: false }));
          mutate('DASHBOARD');
        }}
        steps={steps}
        run={run}
      />

      <SelectModeControls
        onClear={() => setSelectedContexts([])}
        allRowsSelcted={table.getIsAllRowsSelected()}
        toggleAllRowsSelected={table.toggleAllRowsSelected}
        selectedContexts={selectedContexts}
      />
      <motion.div
        className="mt-2 overflow-auto"
        layout="position"
        ref={tableContainerRef}
      >
        <Table
          showSkeleton={isPreviousData}
          size="manual"
          hideVisibilityAndOrdering
          tableHeaderClassName={() => 'whitespace-nowrap'}
          headerPrefix={(header) =>
            header.id === 'converted_salary' ? (
              <SalaryColumnConfigurationDialog>
                <Icon icon="teenyicons:adjust-horizontal-alt-solid" />
              </SalaryColumnConfigurationDialog>
            ) : null
          }
          table={table}
        />
      </motion.div>
    </>
  );
};

export default DashboardTableLayout;
