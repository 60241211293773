import { Icon } from '@iconify/react';
import { Popover, PopoverTrigger } from '@mindpal-co/mindpal-ui';
import classNames from 'classnames';
import type { FC } from 'react';

import Text from '@/components/Text';
import type { Note as NoteType } from '@/services/externalCandidates';

import Button from '../../../Button';
import AddNoteDialog from '../../../Profile/AddNoteDialog';
import Note from '../../../Profile/Notes/Note';
import { PopoverContent } from '../../../ui/popover';

type Props = {
  notes: NoteType[];
  candidateId: string;
  iconClassName?: string;
};

const NotesPopover: FC<Props> = ({ notes, candidateId, iconClassName }) => {
  return (
    <Popover>
      <PopoverTrigger className="group">
        <Icon
          icon="fluent:note-20-filled"
          className={classNames(
            'mt-1 text-xl group-disabled:text-neutral-800',
            iconClassName
          )}
        />
        {notes.length > 0 && <Text variant="body-small">{notes.length}</Text>}
      </PopoverTrigger>
      <PopoverContent className="relative flex max-h-96 min-w-[14rem] max-w-sm flex-col gap-2 overflow-auto border-none bg-transparent p-0">
        {notes.map((note) => (
          <Note candidateId={Number(candidateId)} note={note} key={note.id} />
        ))}
        <AddNoteDialog
          id={Number(candidateId)}
          dialogTriggerProps={{ asChild: true }}
        >
          <Button
            size="sm"
            variant="jbPrimary"
            className="sticky bottom-0 shrink-0"
          >
            Add note
          </Button>
        </AddNoteDialog>
      </PopoverContent>
    </Popover>
  );
};

export default NotesPopover;
