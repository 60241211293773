import { createColumnHelper } from '@tanstack/react-table';

import Checkbox from '@/components/Form/Fields/Checkbox';
import Text from '@/components/Text';
import type { ExternalCandidateList } from '@/services/externalCandidates';
import type { OfferSearch } from '@/services/reasoningSearch';
import type { Filters } from '@/stores/useDashboardFiltersStore';
import { dateFormatter } from '@/utils/date';

import Collections from './Cells/Collections';
import FullName from './Cells/FullName';
import Languages from './Cells/Languages';
import NotesPopover from './Cells/NotesPopover';
import Resume from './Cells/Resume';
import Salary from './Cells/Salary';
import SeniorityLevel from './Cells/SeniorityLevel';
import Settings from './Cells/Settings';
import Skills from './Cells/Skills';
import EditableCell from './EditableCell';
import { salaryHeader } from './Headers/salaryHeader';
import SettingsHeader from './Headers/SettingsHeader';
import ProcessingWrapper from './ProcessingWrapper';

const columnHelper = createColumnHelper<
  ExternalCandidateList & {
    reasoningSearchResult?: OfferSearch;
    filters?: Filters;
  }
>();

export const columns = ({ onRetry }: { onRetry: (id: number) => void }) => [
  columnHelper.display({
    id: 'select',
    cell: ({ row }) => {
      return (
        <Checkbox
          {...{
            variant: 'transparent',
            className: '!p-0',
            name: `check${row.index.toString()}`,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      );
    },
  }),
  columnHelper.accessor('name', {
    header: 'Full name',
    cell: (cellProps) => <FullName cellProps={cellProps} onRetry={onRetry} />,
  }),
  columnHelper.accessor('candidateDetailedInfo.jobTitle', {
    header: 'Job title',
    size: 300,
    cell: ({ getValue }) => {
      return <Text variant="body-small">{getValue()}</Text>;
    },
  }),
  columnHelper.display({
    id: 'resume',
    header: 'Resume',
    cell: ({ row, table }) => <Resume row={row} table={table} />,
  }),
  columnHelper.accessor('candidateDetailedInfo.seniorityLevel', {
    header: 'Seniority',
    cell: ({ getValue, row }) => {
      return (
        <ProcessingWrapper row={row}>
          <SeniorityLevel value={getValue()} />
        </ProcessingWrapper>
      );
    },
  }),
  columnHelper.accessor('candidateDetailedInfo.skills', {
    header: 'Skills',
    cell: ({ getValue, row }) => {
      const { aiParsedFilters, keywords } =
        row.original.reasoningSearchResult ?? {};
      const { skills } = row.original.filters ?? {};

      return (
        <ProcessingWrapper row={row}>
          <Skills
            value={getValue()}
            filterSkills={[
              ...(aiParsedFilters?.skills ?? []),
              ...(keywords?.keywords ?? []),
              ...(keywords?.suggestedKeywords ?? []),
              ...(skills ?? []),
            ]}
          />
        </ProcessingWrapper>
      );
    },
  }),
  columnHelper.accessor('languages', {
    header: 'Languages',
    cell: ({ getValue, row }) => (
      <ProcessingWrapper row={row}>
        <Languages value={getValue()} />
      </ProcessingWrapper>
    ),
  }),
  columnHelper.accessor('email', {
    header: 'Email',
    cell: (cellProps) => {
      if (cellProps.table.options.meta?.editModeActive)
        return <EditableCell cellProps={cellProps} />;
      return cellProps.getValue();
    },
  }),
  columnHelper.accessor('phoneNumber', {
    header: 'Phone number',
    cell: (cellProps) => {
      if (cellProps.table.options.meta?.editModeActive)
        return <EditableCell type="number" cellProps={cellProps} />;
      return <Text className="truncate">{cellProps.getValue()}</Text>;
    },
  }),
  columnHelper.accessor('collections', {
    header: 'Collections',
    cell: ({ row, getValue }) => <Collections value={getValue()} row={row} />,
  }),

  columnHelper.accessor('createdAt', {
    header: 'Created at',
    cell: ({ getValue }) =>
      dateFormatter({
        date: getValue(),
        format: { dateStyle: 'short' },
      }),
  }),
  columnHelper.accessor('updatedAt', {
    header: 'Updated at',
    cell: ({ getValue }) =>
      dateFormatter({ date: getValue(), format: { dateStyle: 'short' } }),
  }),
  columnHelper.accessor('notes', {
    header: 'Notes',
    cell: ({ getValue, row }) => (
      <NotesPopover
        candidateId={row.id}
        notes={getValue()}
        iconClassName="ml-3"
      />
    ),
  }),
  columnHelper.accessor('convertedSalary', {
    meta: { altHeader: 'Salary' },
    id: 'converted_salary',
    enableSorting: true,
    header: ({ table }) => salaryHeader({ table }),
    cell: ({ getValue, table, row }) => (
      <Salary row={row} salary={getValue()} table={table} />
    ),
  }),
  columnHelper.display({
    id: 'settings',
    header: ({ table }) => <SettingsHeader table={table} />,
    cell: ({ row }) => <Settings row={row} />,
  }),
];
