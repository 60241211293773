import classNames from 'classnames';
import type { FC } from 'react';

import Chip from '@/components/Chip';
import { SeniorityLevelEnum } from '@/services/externalCandidates';
import { capitalizeFirstLetter } from '@/utils/cvGenerator';

type Props = {
  value: SeniorityLevelEnum | null;
};

const SeniorityLevel: FC<Props> = ({ value }) => {
  if (!value) return null;
  return (
    <Chip
      variant="unstyled"
      size="sm"
      className={classNames('w-fit !py-0.5', {
        'bg-neutral-700': value === SeniorityLevelEnum.INTERN,
        'bg-successDark/90': value === SeniorityLevelEnum.JUNIOR,
        'bg-secondary-500/70': value === SeniorityLevelEnum.MID,
        'bg-jb-alert-400/70': value === SeniorityLevelEnum.SENIOR,
        'bg-jb-secondary-500/80': value === SeniorityLevelEnum.LEAD,
      })}
    >
      {capitalizeFirstLetter(value)}
    </Chip>
  );
};

export default SeniorityLevel;
