import type { FC } from 'react';

import ChipsWithPopover from '../ChipsWithPopover';

type Props = {
  value: string[];
  filterSkills: string[];
};

const Skills: FC<Props> = ({ value, filterSkills }) => {
  const sortedSkills = value.sort(
    (a, b) =>
      Number(filterSkills?.includes(b)) - Number(filterSkills?.includes(a))
  );

  return (
    <ChipsWithPopover value={sortedSkills} highlightValues={filterSkills} />
  );
};

export default Skills;
