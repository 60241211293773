import { Icon } from '@iconify/react';
import { Popover, PopoverTrigger } from '@mindpal-co/mindpal-ui';
import classNames from 'classnames';
import type { FC } from 'react';

import Chip from '@/components/Chip';
import Text from '@/components/Text';
import { PopoverContent } from '@/components/ui/popover';

type Props = {
  value: string[];
  highlightValues?: string[];
};
const ChipsWithPopover: FC<Props> = ({ value, highlightValues }) => {
  const firtsThreeValues = value.slice(0, 3);
  const restValues = value.slice(3);
  const restValuesMatchToFiltred = restValues.filter((singleRestValue) =>
    highlightValues?.includes(singleRestValue)
  );

  return (
    <div className="flex w-full flex-row gap-2">
      <Chips values={firtsThreeValues} highlightValues={highlightValues} />
      {restValues.length > 0 && (
        <Popover>
          <PopoverTrigger className="w-max">
            <Text className="text-neutral-100" variant="body-caption">
              + {restValues.length}{' '}
              {restValuesMatchToFiltred.length > 0 && (
                <span className="font-bold text-primary-400">{`(${restValuesMatchToFiltred.length})`}</span>
              )}
            </Text>
            <Icon
              className="text-primary-100"
              fontSize={10}
              icon="bi:caret-down-fill"
            />
          </PopoverTrigger>
          <PopoverContent>
            <Chips
              values={restValues}
              className="flex-wrap"
              highlightValues={highlightValues}
            />
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};

export default ChipsWithPopover;

const Chips = ({
  values,
  className,
  highlightValues,
}: {
  values: string[];
  className?: string;
  highlightValues?: string[];
}) => {
  return (
    <div className={classNames('flex flex-row gap-2', className)}>
      {values.map((value) => (
        <Chip
          size="md"
          key={value}
          variant="gray-outline"
          className={classNames('w-max', {
            'text-primary-300 font-medium !border-primary-500': highlightValues
              ?.map((singleHighlightValue) =>
                singleHighlightValue.toLocaleLowerCase()
              )
              ?.includes(value.toLocaleLowerCase()),
          })}
        >
          {value}
        </Chip>
      ))}
    </div>
  );
};
