import { Icon } from '@iconify/react';
import type { FC } from 'react';

import { downloadUrl } from '@/components/CVGenerator/DownloadButton';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

import type { Row } from './types';

type Props = {
  row: Row;
};

const Settings: FC<Props> = ({ row }) => {
  const cvContextsIds = row.original.cvContexts.map(({ id }) => id);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="group"
        disabled={cvContextsIds.length <= 0}
      >
        <Icon
          fontSize={36}
          icon="material-symbols-light:download"
          className="mt-1 group-disabled:text-neutral-800"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-fit">
        <a href={downloadUrl(cvContextsIds, 'PDF')}>
          <DropdownMenuItem>Save as .PDF</DropdownMenuItem>
        </a>
        <a href={downloadUrl(cvContextsIds, 'DOCX')}>
          <DropdownMenuItem>Save as .DOCX</DropdownMenuItem>
        </a>
        <a href={downloadUrl(cvContextsIds, 'JSON')}>
          <DropdownMenuItem>Save as .JSON</DropdownMenuItem>
        </a>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default Settings;
