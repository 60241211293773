import { type FC, type PropsWithChildren, useMemo } from 'react';

import CircularLoadingIndicator from '@/components/CircularLoadingIndicator';

import type { Row } from './Cells/types';

const ProcessingWrapper: FC<PropsWithChildren<{ row: Row }>> = ({
  children,
  row,
}) => {
  const render = useMemo(() => {
    if (row.original.candidateDetailedInfo) return children;
    return null;
  }, [row.original, children]);

  if (row.original.status === 'PROCESSING') {
    return <CircularLoadingIndicator size="sm" />;
  }
  return (
    <div
      key={row.original.id + JSON.stringify(row.original.candidateDetailedInfo)}
    >
      {render}
    </div>
  );
};

export default ProcessingWrapper;
