import { faEdit, faGear, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';

import ColumnsVisibilityAndOrdering from '@/components/ColumnsVisibilityAndOrdering';

import type { Table } from '../Cells/types';

type Props = {
  table: Table;
};

const SettingsHeader: FC<Props> = ({ table }) => {
  return (
    <div className="z-10 flex gap-4">
      <div>
        <ColumnsVisibilityAndOrdering
          hiddenColumnsIds={['select', 'settings']}
          table={table}
        >
          <FontAwesomeIcon icon={faGear} />
        </ColumnsVisibilityAndOrdering>
      </div>
      <FontAwesomeIcon
        icon={table.options.meta?.editModeActive ? faXmark : faEdit}
        onClick={() => table.options.meta?.setEditModeActive((prev) => !prev)}
        className="cursor-pointer"
      />
    </div>
  );
};

export default SettingsHeader;
