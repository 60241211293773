import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type { ElementType, FC, PropsWithChildren } from 'react';

export const chipStyles = cva(
  'flex shrink-0 items-center justify-center gap-2 rounded-full border-2 border-transparent transition-colors',
  {
    variants: {
      variant: {
        success: 'bg-[#3BA5334D] text-neutral-900',
        'jb-success':
          '!border !border-jb-success-600 bg-jb-success-100 text-jb-success-600',
        secondary: 'bg-secondary-500/20 text-neutral-200',
        alert: 'bg-jb-alert-100 text-jb-alert-600',
        warning: 'bg-jb-secondary-200 text-jb-secondary-600',
        primary: 'bg-primary-600 text-neutral-100',
        'neutral-700': 'bg-neutral-700 text-neutral-100',
        'primary-light': 'bg-primary-100 text-primary-800',
        'rounded-light-gray':
          'rounded-full !border !border-[#636363] bg-transparent text-[#636363]',
        'jb-neutral': 'bg-jb-neutral-200 text-jb-neutral-1000',
        'candidate-primary-outline':
          '!border !border-jb-primary-200 bg-transparent text-jb-neutral-800',
        'gray-outline':
          'rounded-lg !border !border-neutral-600 !bg-neutral-900 text-neutral-100',
        unstyled: '',
      },
      size: {
        sm: 'px-[0.52rem] py-[0.28rem] text-[0.65rem] leading-[150%]',
        'sm-long': 'px-4 py-0.5 text-sm leading-[150%]',
        md: 'px-[0.62rem] py-[0.38rem] text-[0.75rem] leading-[150%]',
      },
    },
    defaultVariants: {
      variant: 'success',
      size: 'md',
    },
  }
);
export type ChipVartiantProps = VariantProps<typeof chipStyles>;
type Props = {
  onClick?: () => void;
  className?: string;
} & ChipVartiantProps;

const Chip: FC<PropsWithChildren<Props>> = ({
  onClick,
  variant,
  className,
  size,
  children,
}) => {
  const Element: ElementType = onClick ? 'button' : 'div';
  return (
    <Element
      type={onClick && 'button'}
      onClick={onClick}
      className={`${chipStyles({ variant, size })} ${className}`}
    >
      {children}
    </Element>
  );
};

export default Chip;
