/* eslint-disable react/no-array-index-key */
import {
  faArrowDown,
  faArrowUp,
  faGripVertical,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type {
  Cell,
  Header,
  Row,
  RowData,
  Table as TableType,
} from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import type { ComponentProps } from 'react';

import ColumnsVisibilityAndOrdering from './ColumnsVisibilityAndOrdering';
import { Skeleton } from './ui/skeleton';

type Props<T extends RowData> = {
  table: TableType<T>;
  headerPrefix?: (header: Header<T, unknown>) => JSX.Element | undefined | null;
  headerSuffix?: (header: Header<T, unknown>) => JSX.Element | undefined | null;
  tableHeadClassName?: string;
  tableHeaderClassName?: (header: Header<T, unknown>) => string;
  visibilityAndOrderingProps?: Omit<
    ComponentProps<typeof ColumnsVisibilityAndOrdering>,
    'table'
  >;
  tableHeaderLabelContainerClassName?: (header: Header<T, unknown>) => string;
  tableCellClassName?: (cell: Cell<T, unknown>) => string;
  tableHeaderWrapClassName?: (header: Header<T, unknown>) => string;
  hideVisibilityAndOrdering?: boolean;
  size?: 'manual' | 'auto';
  onRowClick?: (row: Row<T>) => void;
  showDummyData?: boolean;
  dummyDataLength?: number;
  showSkeleton?: boolean;
};
const Table = <T extends RowData>({
  table,
  headerPrefix,
  headerSuffix,
  tableHeadClassName,
  visibilityAndOrderingProps,
  hideVisibilityAndOrdering = false,
  tableHeaderClassName,
  tableCellClassName,
  tableHeaderLabelContainerClassName,
  size = 'auto',
  onRowClick,
  dummyDataLength = 10,
  showDummyData = false,
  showSkeleton = false,
  tableHeaderWrapClassName,
}: Props<T>) => {
  const shouldUseTableSize = size === 'manual';
  return (
    <table
      style={{ width: shouldUseTableSize ? table.getTotalSize() : undefined }}
      className="mb-3 min-w-full border-separate border-spacing-0 overflow-auto rounded-lg [&_th:first-child]:w-[20px] [&_th:first-of-type]:rounded-tl-lg [&_th:last-child]:w-[20px] [&_th:last-of-type]:rounded-tr-lg [&_tr:last-of-type_td:first-of-type]:rounded-bl-lg [&_tr:last-of-type_td:last-of-type]:rounded-br-lg [&_tr_td:first-child]:border-l [&_tr_td:last-child]:border-r [&_tr_td]:border-b [&_tr_th:first-child]:border-l [&_tr_th:last-child]:border-r [&_tr_th]:border-y [&tr_th]:border-t"
    >
      <thead
        className={classNames(
          'border-b transition-colors border-neutral-600 bg-neutral-1000',
          tableHeadClassName
        )}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className={classNames(
                  'group border-neutral-600 p-3 text-left relative',
                  tableHeaderClassName?.(header),
                  {
                    'bg-primary-500/50': header.column.getIsResizing(),
                  }
                )}
                key={header.id}
                style={{
                  width: shouldUseTableSize ? header.getSize() : undefined,
                }}
              >
                {header.column.getCanResize() && (
                  <div
                    className={classNames(
                      'absolute right-0 top-0 h-full w-5 hover:cursor-col-resize group-hover:bg-neutral-800',
                      {
                        '!bg-primary-300': header.column.getIsResizing(),
                        'cursor-col-resize': header.column.getIsResizing(),
                      }
                    )}
                    onClickCapture={(e) => e.stopPropagation()}
                    onMouseDownCapture={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    onDoubleClick={header.column.resetSize}
                  />
                )}
                <div
                  className={classNames(
                    'flex items-center gap-2',
                    tableHeaderWrapClassName?.(header)
                  )}
                >
                  {!hideVisibilityAndOrdering && (
                    <ColumnsVisibilityAndOrdering
                      {...visibilityAndOrderingProps}
                      table={table}
                    >
                      <FontAwesomeIcon
                        icon={faGripVertical}
                        size="xs"
                        className="!hidden  group-hover:!block"
                      />
                    </ColumnsVisibilityAndOrdering>
                  )}
                  {headerPrefix?.(header)}
                  <div
                    role="presentation"
                    className={classNames(
                      'flex w-fit items-center gap-1',
                      {
                        'cursor-pointer': header.column.getCanSort(),
                      },
                      tableHeaderLabelContainerClassName?.(header)
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {{
                      asc: <FontAwesomeIcon icon={faArrowUp} />,
                      desc: <FontAwesomeIcon icon={faArrowDown} />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                  {headerSuffix?.(header)}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="divide-y divide-neutral-600 overflow-auto">
        {showDummyData &&
          Array.from({ length: dummyDataLength }).map((_, index) => (
            <tr
              key={index}
              className={classNames(
                'bg-transparent hover:!bg-purple-500/20 transition-colors',
                {
                  '!bg-neutral-1000/50': index % 2 !== 0,
                }
              )}
            >
              {table.getVisibleLeafColumns().map((__, tdIndex) => (
                <td
                  className="relative h-px border-neutral-600 px-3 py-2"
                  key={tdIndex}
                >
                  <Skeleton className="h-[42px] rounded-none" />
                </td>
              ))}
            </tr>
          ))}
        {table.getRowModel().rows.map((row, index) => {
          return (
            <tr
              onClick={() => onRowClick?.(row)}
              className={classNames(
                'bg-transparent hover:!bg-purple-500/20 transition-colors',
                {
                  '!bg-neutral-1000/50': index % 2 !== 0,
                  'cursor-pointer': !!onRowClick,
                }
              )}
              key={row.id}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  className={classNames(
                    'relative h-px border-neutral-600 px-3 py-2',
                    tableCellClassName?.(cell)
                  )}
                  key={cell.id}
                  style={{
                    width: shouldUseTableSize
                      ? cell.column.getSize()
                      : undefined,
                  }}
                >
                  {showSkeleton && (
                    <Skeleton className="absolute left-0 top-0 z-50 h-full w-full rounded-none opacity-60 transition-all" />
                  )}
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
