import type { FC } from 'react';

import { useLanguages } from '@/hooks/useLanguages';
import type { ExternalCandidateList } from '@/services/externalCandidates';

import ChipsWithPopover from '../ChipsWithPopover';

type Props = {
  value: ExternalCandidateList['languages'];
};

const Languages: FC<Props> = ({ value }) => {
  const { data } = useLanguages();
  const valuesId = value.map((lang) => lang.language);
  const languages = data?.results
    .filter((lang) => valuesId.includes(lang.id))
    .map((lang) => lang.name);
  return <ChipsWithPopover value={languages ?? []} />;
};

export default Languages;
