import type { CellContext } from '@tanstack/react-table';
import classNames from 'classnames';
import type { ChangeEvent, FC } from 'react';
import { useEffect, useState } from 'react';
import { string } from 'yup';

import type { ExternalCandidateList } from '@/services/externalCandidates';

type EditableCellProps = {
  cellProps: CellContext<ExternalCandidateList, unknown>;
  type?: 'text' | 'number';
};
const EditableCell: FC<EditableCellProps> = ({
  cellProps: {
    getValue,
    table,
    row: { id: rowId },
    column: { id },
  },
  type = 'text',
}) => {
  const initialValue = getValue();
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<string | null>(null);

  const onBlur = () => {
    if (!error) table.options.meta?.updateData(rowId, id, value);
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b +()-]+$/;
    if (
      type === 'number' &&
      e.target.value !== '' &&
      !re.test(e.target.value) &&
      e.target.value.length > (value as string).length
    )
      return;

    setValue(e.target.value);
    if (id === 'email')
      await string()
        .email()
        .validate(e.target.value)
        .then(() => setError(null))
        .catch((reason) => setError(reason.message));
  };
  return (
    <div className="cursor-text rounded-lg bg-neutral-900 px-2">
      <input
        size={(value as string).length}
        className={classNames(
          'border-none bg-transparent w-full outline-none',
          {
            'text-error': error,
          }
        )}
        value={value as string}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default EditableCell;
